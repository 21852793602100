



















































import PointOfServiceForm from '@/components/pointofservice/PointOfServiceForm.vue';
import PointOfServiceUtils, {
  PointOfService,
} from '@/utils/PointOfServiceUtils';
import Vue from 'vue';

export default Vue.extend({
  components: { PointOfServiceForm },

  data(): {
    state: {
      valid: boolean;
      submit: boolean;
      delete: boolean;
      errors?: any[];
    };
    item?: Partial<PointOfService>;
    disableGeocoder?: boolean;
  } {
    return {
      state: {
        valid: false,
        submit: false,
        delete: false,
        errors: undefined,
      },
      item: undefined,
      disableGeocoder: false,
    };
  },

  async activated() {
    try {
      this.item = undefined;
      this.disableGeocoder = true;
      const result = await PointOfServiceUtils.api.get(
        parseInt(this.$route.params?.id)
      );
      this.item = PointOfServiceUtils.pointOfService.generateDefault(result);
      (this.$refs.form as any).resetValidation();

      setTimeout(() => {
        // delay enabling geocoder for a bit so it will not trigger as soon as the item is set
        this.disableGeocoder = false;
      }, 50);
    } catch (error: any) {
      console.error(error);
      if (error.response.status === 404) {
        // TODO: not found
      }
    }
  },

  methods: {
    async handleSubmit() {
      if (this.item) {
        try {
          this.state.errors = undefined;
          this.state.submit = true;
          (this.$refs.form as any).validate();

          this.item = PointOfServiceUtils.pointOfService.generateDefault(
            await PointOfServiceUtils.api.update(this.item)
          );

          await PointOfServiceUtils.api.generateSearchDataset(this.item?.id);

          (this.$refs.form as any).resetValidation();
          this.$toast.push({
            text: this.$t('success.edit', [this.$tc('pointofservice.label')]),
            type: 'success',
          });
        } catch (error: any) {
          this.$toast.push({
            text: this.$t('error.edit', [this.$tc('pointofservice.label')]),
            type: 'error',
          });
          this.state.errors = error; // TODO: handle errors
        } finally {
          this.state.submit = false;
        }
      }
    },

    async handleDelete() {
      try {
        this.state.errors = undefined;
        this.state.delete = true;
        await PointOfServiceUtils.api.remove(parseInt(this.$route.params?.id));
        this.$toast.push({
          text: this.$t('success.delete', [this.$tc('pointofservice.label')]),
          type: 'success',
        });
        this.$router.replace({ name: 'pointofservice-list' });
      } catch (error: any) {
        this.$toast.push({
          text: this.$t('error.delete', [this.$tc('pointofservice.label')]),
          type: 'error',
        });
        this.state.errors = error; // TODO: handle errors
      } finally {
        this.state.delete = false;
      }
    },

    async handleGenerateDataSet() {
      // TODO TEMP DELETE
      await PointOfServiceUtils.api.generateSearchDataset(this.item?.id);
    },
  },
});
